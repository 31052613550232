import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import contact from "../images/contact-color.svg";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import application_received from "../images/application_received.svg";
import { useForm } from "react-hook-form";
import bitrixWebhook from "../ApiUrls/bitrixApiUrls";
import { telenorCmsAuth, telenorCmsSMS } from "../ApiUrls/telenorApiUrls";
import { creditPerPhoneNo } from "../creditPerDetails";

function FeedBackModal(props) {
  const { fullName } = props;
  return (
    <Modal
      {...props}
      size="lg"
      fullName={fullName}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="react-bootstrap-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="contact__dialog-wrapper">
          <Image src={application_received} className="contact__dialog-image" />
        </div>
        <p className="contact__dialog-text">
          {`Hi, ${fullName}, CreditPer ke saath register kernay ka shukriya, qiston per products khareednay ya baychnay k liye humaray customer support department say raabta karein.`}
        </p>
        <p className="contact__dialog-text">
          {`Customer Support Department Phone Number: ${creditPerPhoneNo}`}
        </p>
      </Modal.Body>
    </Modal>
  );
}

const ContactUs = ({ getTranslatedText }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const [full_name, setFullName] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const formSubmissionEvent = () => {
    window.fbq("track", "Contact Us Form Submission");
    window.gtag("event", "Contact Us Form Submission", {
      event_category: "Button",
      event_label: "Buttons",
    });
  };

  const onSubmit = async (e) => {
    if (!full_name || !email || !phone || !city || !message) return;
    const bitrix_response = await submit_bitrix_form(
      full_name,
      email,
      phone,
      city,
      message
    );
    const result = bitrix_response;

    if (result) {
      formSubmissionEvent();
    } else {
      return bitrix_response;
    }
  };

  const submit_bitrix_form = async () => {
    const response = await axios.all([
      axios.post(`${bitrixWebhook}/crm.contact.add.json`, {
        fields: {
          SOURCE_ID: "CreditPer Website",
          SOURCE_DESCRIPTION: "CreditPer Website",
          TITLE: `Website Contact Us Form - ${full_name}`,
          NAME: full_name,
          UF_CRM_1643785837253: phone,
          UF_CRM_1643786086580: email,
          UF_CRM_1643785851386: message,
          UF_CRM_1643786098254: city,
        },
      }),
      axios.get(`${telenorCmsAuth}`),
    ]);

    setModalShow(true);
    const telenorAPIData = response[1].data;

    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(telenorAPIData, "text/xml");

    const sessionID =
      xmlDoc.getElementsByTagName("data")[0].childNodes[0].nodeValue;

    const replySMS = `Hi, ${full_name}, CreditPer ke saath register kernay ka shukriya, qiston per products khareednay ya baychnay k liye humaray customer support department say raabta karein. Phone no: ${creditPerPhoneNo}`;

    const mask = "CreditPer";

    axios.post(
      `${telenorCmsSMS}?session_id=${sessionID}&to=${phone}&text=${replySMS}&mask=${mask}`
    );

    return response;
  };

  return (
    <>
      <FeedBackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        fullName={full_name}
      />
      <section id="contact" className="contact">
        <Container className="contact__container">
          <Row className="contact__row">
            <Col className="contact__col-right">
              <Image className="contact__image" src={contact} fluid={false} />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={11}
              lg={9}
              xl={8}
              className="contact__col-left"
            >
              <div className="contact__form-wrapper">
                <h2 className="contact__form--heading">
                  {getTranslatedText("ContactUs Form")}
                </h2>
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  className="contact__form"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <label className="contact__form--label">
                    {getTranslatedText("ContactForm Name")}
                    <input
                      {...register("fullname", {
                        required: true,
                      })}
                      name="fullname"
                      id="fullname"
                      placeholder={getTranslatedText("ContactForm NameHolder", false)}
                      className="contact__form--input"
                      value={full_name}
                      type="fullname"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </label>
                  {errors?.fullname?.type === "required" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm NameVal")}
                    </p>
                  )}

                  <label className="contact__form--email-label">
                    {getTranslatedText("ContactForm Email")}
                    <input
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/,
                      })}
                      name="email"
                      id="email"
                      placeholder={getTranslatedText("ContactForm EmailHolder", false)}
                      className="contact__form--input-email"
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                  {errors?.email?.type === "required" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm EmailVal")}
                    </p>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm ValidEmailVal")}
                    </p>
                  )}
                  <label className="contact__form--email-label">
                    {getTranslatedText("ContactForm Phone")}
                    <input
                      {...register("phone", {
                        required: true,
                        pattern: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
                      })}
                      name="phone"
                      placeholder={getTranslatedText("ContactForm PhoneHolder", false)}
                      className="contact__form--input-email"
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </label>
                  {errors.phone?.type === "required" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm PhoneVal")}
                    </p>
                  )}
                  {errors?.phone?.type === "pattern" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm ValidPhoneVal")}
                    </p>
                  )}
                  <label className="contact__form--label">
                    {getTranslatedText("ContactForm City")}
                    <input
                      {...register("city", {
                        required: true,
                      })}
                      name="city"
                      id="city"
                      placeholder={getTranslatedText("ContactForm CityHolder", false)}
                      className="contact__form--input"
                      value={city}
                      type="text"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </label>
                  {errors?.city?.type === "required" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm CityVal")}
                    </p>
                  )}

                  <label className="contact__form--message-label">
                    {getTranslatedText("ContactForm Message")}
                    <textarea
                      {...register("message", {
                        required: true,
                      })}
                      rows="5"
                      name="message"
                      id="message"
                      placeholder={getTranslatedText(
                        "ContactForm MessageHolder", false
                      )}
                      className="contact__form--input"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </label>
                  {errors.message?.type === "required" && (
                    <p className="contact__form--error">
                      {getTranslatedText("ContactForm MessageVal")}
                    </p>
                  )}

                  <div className="contact__form--button-wrapper">
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-md mr-1"></span>
                    )}
                    <button
                      type="submit"
                      value="Send Message"
                      className="contact__form--button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {getTranslatedText("ContactForm Button")}
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
