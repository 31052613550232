import React, { useEffect, useState } from "react";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import HeroLanding from "../components/HeroLanding";
import Partners from "../components/Partners";
import Process from "../components/Process";
import Wrapper from "../components/Wrapper";
import Testimonials from "../components/Testimonials";
import WhatsappChat from "../components/WhatsappChat";
import HeroFormContent from "../components/HeroFormContent";
import { localStorageKeys } from "../utility/localstorage";
import { getTranslatedText } from "../utility/constant";
import { SEO } from "../components/Seo";

const IndexPage = () => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <div>
      <Wrapper
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
      >
        <HeroLanding
          myCustomAppState={myCustomAppState}
          setMyCustomAppState={setMyCustomAppState}
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <HeroFormContent
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <Process
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <Testimonials
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <Partners
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <ContactUs
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <Footer
          getTranslatedText={(key, asElement) =>
            getTranslatedText(myCustomAppState.language, key, asElement)
          }
        />
        <WhatsappChat />
      </Wrapper>
    </div>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
