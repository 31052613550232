import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeroForm from "../components/HeroForm";
import Line from "./Line";
import SellerSVG from "../images/seller-color.svg";

const HeroFormContent = ({
  getTranslatedText,
  myCustomAppState,
  setMyCustomAppState,
}) => {
  return (
    <section className="hero-content">
      <Container>
        <Row>
          <Col className="hero-content__col">
            <Line type="large" />
            <h2 className="hero-content__heading">
            {getTranslatedText("HeroForm Button")}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} xl={6} className="hero-content__col">
            <Image src={SellerSVG} />
          </Col>
          <Col lg={6} xl={6} className="hero-content__col">
          <HeroForm
              myCustomAppState={myCustomAppState}
              setMyCustomAppState={setMyCustomAppState}
              getTranslatedText={getTranslatedText}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroFormContent;
