import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import playicon from '../images/play-button.svg';

function VideoModal(props) {
  const { video_id } = props;

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <iframe
          title='video'
          src={`https://www.youtube.com/embed/${video_id}`}
          className='testimonial-card__youtube-video'></iframe>
      </Modal.Body>
    </Modal>
  );
}

const TestimonialCard = (props) => {
  const [modalShow, setModalShow] = useState(false);

  const { image, video_id, customerName, customerReview } = props;

  return (
    <>
      <VideoModal
        video_id={video_id}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className='testimonial-card' onClick={() => setModalShow(true)}>
        <div className='testimonial-card__image-wrapper'>
          <img  
            alt='image'
            src={image}
            fluid={false}
            className='testimonial-card__image'
          />
          <img alt='image' src={playicon} className='testimonial-card__play' />
        </div>
        <div className='testimonial-card__text-wrapper'>
          <p className='testimonial-card__customer-text'>{customerReview}</p>
          <p className='testimonial-card__customer-name'>{customerName}</p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
