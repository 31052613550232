import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Line from '../components/Line';
import ProcessCard from '../components/ProcessCard';
import Otpscreen from '../images/otpscreen.png';
import Product from '../images/product.png';
import Details from '../images/productdetails.png';
import Profile from '../images/profile.png';
import Pay from '../images/script.png';  

const Process = ({getTranslatedText}) => { 

  return (
    <section id='process' className='process'>
      <Container>
        <Row className='process__upper-row'>
          <Col className='process__col' md={12} lg={12} xl={12}>
            <Line type='large' />
            <h2 className='process__heading'>{getTranslatedText('Process Title')}</h2>
            {/* <div className='process__text'>
              &#10140; Application download kar k sign up karein
            </div>
            <div className='process__text'>
              &#10140; Apni pasandeeda product muntakhib karein
            </div>
            <div className='process__text'>
              &#10140; Apna lease plan muntakhib karein
            </div>
            <div className='process__text'>
              &#10140; Apnay documents upload kar k apni shanakht karwaein
            </div>
            <div className='process__text'>
              &#10140; Apni qareebi easy paisa dukaan par paisay ada karein
            </div> */}
            {/* <br /> */}
            {/* <a
              href='https://play.google.com/store/apps/details?id=pk.creditfixv2.app'
              className='footer__link'>
              <Image
                fluid={true}
                src={playIcon}
                className='footer__google-play'
              />
            </a> */}
          </Col>
          {/* <Col className='process__col-right' md={12} lg={5} xl={5}>
            <div className='process__image-video-wrapper'>
              <Image
                src={process_apply}
                fluid={true}
                className='process__image'
              />
            </div>
          </Col> */}
        </Row>
        <Row className='process__row'>
          <Col className='process__card-image-wrapper' xl={6}>
            <Image src={Otpscreen} className='process__card-image' />
          </Col>
          <Col className='process__card-wrapper' xl={6}>
            <ProcessCard
              count='1'
              title={getTranslatedText('Process Method H1')}
              text={getTranslatedText('Process Method D1')}
            />
          </Col>
        </Row>
        <Row className='process__row'>
          <Col className='process__card-wrapper' xl={6}>
            <ProcessCard
              count='2'
              title={getTranslatedText('Process Method H2')}
              text={getTranslatedText('Process Method D2')}
            />
          </Col>
          <Col className='process__card-image-wrapper' xl={6}>
            <Image src={Product} className='process__card-image' />
          </Col>
        </Row>
        <Row className='process__row'>
          <Col className='process__card-image-wrapper' xl={6}>
            <Image src={Details} className='process__card-image' />
          </Col>
          <Col className='process__card-wrapper' xl={6}>
            <ProcessCard
              count='3'
              title={getTranslatedText('Process Method H3')}
              text={getTranslatedText('Process Method D3')}
            />
          </Col>
        </Row>
        <Row className='process__row'>
          <Col className='process__card-wrapper' xl={6}>
            <ProcessCard
              count='4'
              title={getTranslatedText('Process Method H4')}
              text={getTranslatedText('Process Method D4')}
            />
          </Col>
          <Col className='process__card-image-wrapper' xl={6}>
            <Image src={Profile} className='process__card-image' />
          </Col>
        </Row>
        <Row className='process__row'>
          <Col className='process__card-image-wrapper' xl={6}>
            <Image src={Pay} className='process__card-image' />
          </Col>
          <Col className='process__card-wrapper' xl={6}>
            <ProcessCard
              count='5'
              title={getTranslatedText('Process Method H5')}
              text={getTranslatedText('Process Method D5')}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Process;
