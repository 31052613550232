import React from 'react';
import TestimonialCard from './TestimonialCard';
import { Container, Row, Col } from 'react-bootstrap';
import Line from './Line';
import testimon_image1 from '../images/testimon_image1.jpg';
import testimon_image2 from '../images/testimon_image2.jpg';

const Testimonials = ({getTranslatedText}) => {

  return (
    <>
      <section className='testimonials'>
        <Container className='testimonials__upper-container'>
          <Row>
            <Col xl={12} className='partners__col-upper'>
              <Line type='large' />
              <h2 className='partners__heading'>{getTranslatedText('Customers Title')}</h2>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xl={12} className='testimonials__col-lower'>
              <TestimonialCard
                image={testimon_image1}
                video_id='ZZEH7w5tJwQ'
                customerName={getTranslatedText('Customer NameShehbaz')}
                customerReview={getTranslatedText('Review CustomerShehbaz')}
              />
              <TestimonialCard
                image={testimon_image2}
                video_id='HVRs5dTUBvc'
                customerName={getTranslatedText('Customer NameHaris')}
                customerReview={getTranslatedText('Review CustomerHaris')}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Testimonials;
