import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta property="al:android:package" content="pk.creditfixv2.app" />
      <meta
        property="al:android:url"
        content="https://play.google.com/store/apps/details?id=pk.creditfixv2.app"
      />
      <meta property="al:android:app_name" content="CreditPer" />
      <meta property="al:web:url" content="https://www.creditper.pk/" />

      {children}
    </>
  );
};
