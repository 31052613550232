import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import Button from "./Button";
import { creditPerPhoneNo } from "../creditPerDetails";
import { mobileAppPlayStoreLink } from "../creditPerDetails";
import { localStorageKeys } from "../utility/localstorage";
import { staticWebAppTextTranslation } from "../utility/constant";
import Scooter from "../images/hero-scooter.png";
import logo from "../images/Creditper-Logo.svg";
import phoneCall from "../images/phone-call.svg";

const onDownloadAppButtonClick = () => {
  window.fbq("track", "Download App Button");
  window.gtag("event", "Download App Button", {
    event_category: "Button",
    event_label: "Buttons",
  });
};

const onPhoneCallButtonClick = () => {
  window.fbq("track", "Phone Call Button");
  window.gtag("event", "Phone Call Button", {
    event_category: "Button",
    event_label: "Buttons",
  });
};

const HeroLanding = ({
  getTranslatedText,
  myCustomAppState,
  setMyCustomAppState,
}) => {
  const switchLanguage = () => {
    const switchingToLang = Object.keys(staticWebAppTextTranslation).find(
      (item) => item !== myCustomAppState?.language
    );
    localStorage.setItem(localStorageKeys.language, switchingToLang);
    setMyCustomAppState({ [localStorageKeys.language]: switchingToLang });
  };

  return (
    <section className="hero-landing">
      <div className="header-mobile">
        <div class="btn-container">
          <label class="switch">
            <input
              type="checkbox"
              name="toogle-mobile"
              id="toogle-mobile"
              onClick={switchLanguage}
            />
            <label
              for="toogle-mobile"
              data-on="اردو"
              data-off="En"
              class="switch-inner"
            ></label>
          </label>
        </div>

        <Link
          className="call-content nav-item"
          href={`tel:${creditPerPhoneNo}`}
          onClick={onPhoneCallButtonClick}
        >
          <Image className="phone-icon" src={phoneCall} fluid={true} />
          <div>{creditPerPhoneNo}</div>
        </Link>

        <Image className="logo" src={logo} />
        <p className="logo-text">{getTranslatedText("Headline LogoText")}</p>
      </div>

      <Row>
        <Col  className="col-wrapper">
          <div className="content-wrapper">
            <div className="title-wrapper">
              <h1 className="content">{getTranslatedText("Headline One")}</h1>
              <h1 className="content">{getTranslatedText("Headline Two")}</h1>
              <h1 className="content" style={{ color: "#FF5400" }}>
                {getTranslatedText("Headline Three")}
              </h1>
            </div>

            <a className="button-wrapper" href={mobileAppPlayStoreLink}>
              <Button
                label={getTranslatedText("Download Button")}
                onClick={onDownloadAppButtonClick}
              />
            </a>
          </div>
        </Col>

        <Col className="image-wrapper">
          <Image src={Scooter} />
        </Col>
      </Row>
    </section>
  );
};

export default HeroLanding;
