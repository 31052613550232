import React from 'react';
import PartnerCard from '../components/PartnerCard';
import { Container, Row, Col } from 'react-bootstrap';
import unionstar from '../images/unionstar_logo.jpg';
import unique from '../images/unique_logo.png';
import united from '../images/united_logo.png';
import atlashonda from '../images/atlashonda.png';
import infinix from '../images/infinix_logo.png';
import xiaomi from '../images/xiaomi_logo.png';
import hispeed from '../images/hispeed_logo.jpg';
import suzuki from '../images/suzuki_logo.svg';
import superpower from '../images/superpower_logo.png';
import vivo from '../images/vivo_logo.png';
import Line from './Line';
import "swiper/scss";
import "swiper/scss/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, A11y } from "swiper";

const Partners = ({getTranslatedText}) => {

  SwiperCore.use([Autoplay, A11y]);

  return (
    <section className='partners' id='partnersection'>
      <Container>
        <Row>
          <Col xl={12} className='partners__col-upper'>
            <Line type='large' />
            <h2 className='partners__heading'>{getTranslatedText('Heading OurPartners')}</h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xl={12} className='partners__col-lower'>
             <Swiper
              breakpoints={{
                320: {
                  width: 320,
                  slidesPerView: 1,
                },
                576: {
                  width: 576,
                  slidesPerView: 1,
                },
                640: {
                  width: 640,
                  slidesPerView: 2,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
                992: {
                  width: 992,
                  slidesPerView: 3,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 4,
                },
              }}
              autoplay={{ delay: 3000 }}
            >
              <SwiperSlide>
                  <PartnerCard logo={unionstar} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={unique} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={united} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={atlashonda} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={infinix} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={xiaomi} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={vivo} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={hispeed} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={suzuki} />
              </SwiperSlide>
              <SwiperSlide>
                  <PartnerCard logo={superpower} />
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
